import { atomWithLocalStorage } from "./utils/index";
import { atom, useAtom } from "jotai";
import "./App.css";
import i18n from "./i18n";

import AppList from "./pages/AppList";
import Welcome, { detectSpectodaConnect } from "./pages/Welcome";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import {
   TangleMsgBox,
   i18webcomponents,
} from "./webcomponents/dialog-component";
import { NARA_ICON_DATA_URI } from "./components/AppIcon";
import { useEffect } from "react";

export const phaseAtom = atomWithLocalStorage("phase", 1);
export const loadingAtom = atom<{
   loading: boolean;
   icon: string;
   name: string;
}>({
   loading: false,
   icon: "",
   name: "",
});

const SHOULD_AUTO_REDIRECT = "shouldAutoRedirect";

const shouldAutoRedirect =
   localStorage.getItem(SHOULD_AUTO_REDIRECT) === "true";

const saveAutoRedirectTrue = () => {
   try {
      localStorage.setItem(SHOULD_AUTO_REDIRECT, "true");
   } catch (error) {
      // eslint-disable-next-line no-console
      console.warn("Failed to save welcome drawer state:", error);
   }
};

const urlRoot =
   "https://control.spectoda.com/subdom/control/client--welcome-screen/#/";

const redirect = () => {
   const appCodes = JSON.parse(localStorage.getItem("appcodes") || "[]");
   const encodedCodes = encodeURIComponent(JSON.stringify(appCodes));
   const targetUrl = `${urlRoot}welcome?v1LauncherCodes=${encodedCodes}`;

   saveAutoRedirectTrue();

   window.location.href = targetUrl;
};

const App = () => {
   const { t } = useTranslation();

   useEffect(() => {
      const updateWelcomeScreen = async () => {
         if (shouldAutoRedirect) {
            redirect();
            return;
         }

         const confirmed = await TangleMsgBox.confirm(
            "The app launcher got way simpler and prettier! Can we update it for you?",
            "New version available!",
            { confirm: "OK, Update Now", secondary: null, cancel: "No, Thanks" }
         );

         if (!confirmed) {
            return;
         }

         redirect();
      };

      updateWelcomeScreen();
   }, []);

   i18webcomponents.changeLanguage(i18n.language);

   i18n.exists("");
   const [phase] = useAtom(phaseAtom);
   const motionProps = {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
   };

   return (
      <>
         <div className="bg-[#000422] h-screen w-screen">
            <AnimatePresence initial={false} exitBeforeEnter>
               {phase === 0 ? (
                  <motion.div key="welcome" {...motionProps}>
                     <Welcome />
                  </motion.div>
               ) : (
                  <motion.div key="appList" {...motionProps}>
                     <AppList />
                  </motion.div>
               )}
            </AnimatePresence>
         </div>
         <LoadingApp />
      </>
   );
};

function LoadingApp() {
   const [{ icon, name, loading }] = useAtom(loadingAtom);

   return (
      <div
         className={`${
            loading
               ? "fixed inset-0	bg-[rgba(0,0,0,0.95)] opacity-100"
               : "!hidden"
         } center flex-col z-50`}
      >
         <div className="">
            <img
               src={icon || NARA_ICON_DATA_URI}
               alt="Logo"
               className="w-18 w-48 mt-16 mb-16 mx-auto"
            />
            <p className="text-xl font-bold text-white text-center">{name}</p>
         </div>
      </div>
   );
}

export default App;
