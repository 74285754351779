// @ts-nocheck
import logo from "../assets/logo.png";
import { useAtom } from "jotai";
import { phaseAtom } from "../App";
import { motion } from "framer-motion";
import { TangleButton } from "../components/TangleComponents";
import { useTranslation } from "react-i18next";
import { tangleDevice } from "../communication";

const spectodaConnectDetected = "flutter_inappwebview" in window;
export function detectSpectodaConnect() {
  return spectodaConnectDetected;
}

if (detectSpectodaConnect()) {
  // target="_blank" global handler
  // @ts-ignore

  /** @type {HTMLBodyElement} */ document.querySelector("body").addEventListener("click", function (e) {
    e.preventDefault();

    (function (e, d, w) {
      if (!e.composedPath) {
        e.composedPath = function () {
          if (this.path) {
            return this.path;
          }
          var target = this.target;

          this.path = [];
          while (target.parentNode !== null) {
            this.path.push(target);
            target = target.parentNode;
          }
          this.path.push(d, w);
          return this.path;
        };
      }
    })(Event.prototype, document, window);
    // @ts-ignore
    const path = e.path || (e.composedPath && e.composedPath());

    // @ts-ignore
    for (let el of path) {
      logging.verbose("External url path", el);
      if (el.tagName === "A" && el.getAttribute("target") === "_blank") {
        e.preventDefault();
        const url = el.getAttribute("href");
        // logging.verbose(url);
        // @ts-ignore
        logging.verbose("Openning external url", url);
        window.flutter_inappwebview.callHandler("openExternalUrl", url);
        break;
      }
    }
  });
}

if ("flutter_inappwebview" in window) {
  /** @type {HTMLBodyElement} */ (document.querySelector("body") as HTMLBodyElement).addEventListener("click", function (e) {
    e.preventDefault();
    // @ts-ignore
    for (let el of e.path) {
      if (el.tagName === "A" && el.getAttribute("target") === "_blank") {
        e.preventDefault();
        const url = el.getAttribute("href");
        // console.log(url);
        // @ts-ignore
        console.log("Calling it");
        // @ts-ignore
        window.flutter_inappwebview.callHandler("openExternalUrl", url);
        break;
      }
    }
  });
}

function Welcome(props: any) {
  const { t } = useTranslation();

  const [, setPhase] = useAtom(phaseAtom);
  return (
    <div className="bg-[#000422] text-center text-white">
      <div className="flex justify-center min-h-screen max-h-">
        <div className="container flex flex-col justify-start ">
          <img src={logo} alt="Logo" className="w-18 w-48 mt-16 mb-12 mx-auto" />
          <h2 className="text-2xl font-bold my-4">{t("Vítejte v aplikaci Spectoda Connect")}</h2>
          <p className="opacity-80">{t(`Tato aplikace slouží k propojení Spectoda zařízení s jejich rozhraním pro ovládání. Začněte tím, že si v sekci "Moje aplikace" zvolíte uživatelské rozhraní (např. aplikace NARA lamp).`)}</p>
          <div className="mt-auto" style={{ marginBottom: 12 }}>
            <TangleButton className="btn btn-primary" onClick={() => setPhase(1)}>
              {t("Moje aplikace")}
            </TangleButton>
            <a href="https://spectoda.com/" target="_blank" rel="noreferrer">
              <TangleButton className="btn btn-empty">{t("Co je to Spectoda")}</TangleButton>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
